.loginScreen{
    position: relative;
    height: 100vh;
    overflow: hidden;
    /* background: url("https://www.croda.com/mediaassets/images/corporate/products-and-markets/electronics-and-devices/formulation-solutions-for-the-electronics-industry-from-croda.jpg?la=en-gb&w=1920&focalpointcrop=1&xratio=0&yratio=0&hash=FEA41C7E73FDF26EE8C157C4232FBB7F90B3B8A8") center no-repeat; */
    background-size: cover;
}
.loginScreen__gradient{
    width: 100%;
    z-index: 1;
    height: 100vh;
    background: rgba(0,0,0,0.4);
    background-image: linear-gradient(
        to top,
        rgba(0,0,0,0.8) 0,
        rgba(0,0,0,0) 60%,
        rgba(0,0,0,0.8) 100%
    )
}
.loginScreen__logo{
    position: fixed;
    top: 20px;
    left: 0;
    width: 150px;
    object-fit: contain;
    padding-left: 20px;
}
.Login__logo{
    /* position: fixed;
    top: 20px;
    left: 120px; */
    width: 150px;
    height: 150px;
    object-fit: contain;
    /* padding: 10px; */
    margin-left: auto;
    margin-right: auto;
    display: flex;
}
.loginScreen__button{
    position: fixed;
    right:20px;
    top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #e50914;
    cursor: pointer;
    border: none;
    font-weight: 600;
}
.loginScreen__body{
    position: absolute;
    top: 25%;
    left: 0;
    right: 0;
    z-index: 10;
    color:#fff;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-animation: slide-in-elliptic-top-fwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-elliptic-top-fwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    /* padding:20px;
    margin-left:auto;
    margin-right: auto; */
     
}
.error_div{
    color:red;
    font-weight: 700;
    margin-bottom: 10px;
}
.loginScreen__body > h1{
    font-size: 3.125rem;
    margin-bottom:20px;
}
.loginScreen__body > h2{
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 30px;
}
.loginScreen__body > h3{
    font-size: 1.3rem;
    font-weight: 400;
}
.loginScreen__input > form > input{
         padding: 10px;
         outline-width: 0; 
         height: 30px;
         width: 30%;
         border: none;
         max-width: 600px; 
}
.loginScreen__getstarted{
    padding: 16px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #e50914;
    border: none;
    font-weight: 600;
    cursor: pointer;
}
.loginScreen__input{
    margin: 20px;
}
.signupScreen{
    max-width: 300px;
    padding: 30px;
    margin-left: auto;
    margin-right: auto;
    background: rgba(68,100,115,0.6);
}
.signupScreen > form{
    display:grid;
    flex-direction: column;
}
.signupScreen > form > input{
   outline-width: 0;
   height: 40px;
   margin-bottom: 14px;
   border-radius: 5px;
   border: none;
   padding: 5px 15px;
}
.signupScreen > form > button {
     padding: 16px 20px;
     font-size: 1rem;
     color: #fff;
     border-radius: 5px;
     background-color: #e50914;
     font-weight: 600;
     border: none;
     cursor: pointer;
     margin-top: 20px;
 }
 .signupScreen > form > h1{
    text-align: left;
    margin-bottom: 25px;
 }
 .signupScreen > form > h4{
    text-align: left;
    margin-top: 25px;
 }
 .signupScreen__gray{
   color: gray;
 }
 .signupScreen__link:hover{
    cursor: pointer;
    text-decoration: underline;
 }

 /* ----------------------------------------------
 * Generated by Animista on 2021-12-18 13:25:5
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-elliptic-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-elliptic-top-fwd {
    0% {
      -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
              transform: translateY(-600px) rotateX(-30deg) scale(0);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) rotateX(0) scale(1);
              transform: translateY(0) rotateX(0) scale(1);
      -webkit-transform-origin: 50% 1400px;
              transform-origin: 50% 1400px;
      opacity: 1;
    }
  }
  @keyframes slide-in-elliptic-top-fwd {
    0% {
      -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
              transform: translateY(-600px) rotateX(-30deg) scale(0);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) rotateX(0) scale(1);
              transform: translateY(0) rotateX(0) scale(1);
      -webkit-transform-origin: 50% 1400px;
              transform-origin: 50% 1400px;
      opacity: 1;
    }
  }
  