@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

::-webkit-scrollbar-track {
  /* background: #f1f1f1;  */
}

::-webkit-scrollbar-thumb {
  background: #ffffff;
  width: 2px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b0b0bd;
  border-radius: 10px;
}

.scroller:hover {
  scrollbar-color: #ffffff transparent;
  scrollbar-width: thin;
  scrollbar-gutter: inherit;
}

.scroller {
  scrollbar-color: #dbdbe8 transparent;
  scrollbar-width: thin;
  scrollbar-gutter: unset;
}

.font-link {
  font-family: "Roboto";
}


