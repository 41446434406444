
$sidebar-bg-color: #0f1626;
$sidebar-color: #ffffff!default;
$sidebar-width: 265px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: #d8d8d8 !default;
$submenu-bg-color: #03586A !default;
$submenu-bg-color-collapsed: #03586A !default;
$icon-bg-color: #03586A !default;
$icon-size: 35px !default;
$text-color: #fff;


.buttonClass {
    width:200px;
    padding: 10;
    height:50px;
    border-width:1px;
    color: $text-color;
    border-color:#F02F60;
    font-weight: bold;
    border-radius:6px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: '20px';

    &:hover {
      background: linear-gradient(#FD6B43, #F02F60);
    }
  }
  
                

@import '~react-pro-sidebar/dist/scss/styles.scss';
