@import url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp");

.table {
    border-spacing: 0 15px;
}

i {
    font-size: 1rem !important;
}

.table th:nth-child(n+7) {
    /* border-radius: 20px; */
    border-right: none;
}

tr td:nth-child(n+7),
tr th:nth-child(n+7) {
    /* border-radius: 0 .625rem .625rem 0; */
    /* border: none; */
}

/* tr td:nth-child(1),
tr th:nth-child(1) {
    border-radius: .625rem 0 0 .625rem;
} */

