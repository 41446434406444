@import url('https://cdn.syncfusion.com/ej2/material.css');
/* .app {
  text-align: center;
} */
.e-grid .e-headercell {
  color: darkblue;
}
.app{
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */

}
.app::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}
