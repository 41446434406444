.marker{
    -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
    animation: heartbeat 1.5s ease-in-out infinite both;
}
.hero{
        -webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
        animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;

}
.hero_out{
        -webkit-animation: swing-out-top-bck 0.45s cubic-bezier(0.600, -0.280, 0.735, 0.045) both;
        animation: swing-out-top-bck 0.45s cubic-bezier(0.600, -0.280, 0.735, 0.045) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-12-14 15:5:40
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-out-top-bck
 * ----------------------------------------
 */
 @-webkit-keyframes swing-out-top-bck {
        0% {
          -webkit-transform: rotateX(0deg);
                  transform: rotateX(0deg);
          -webkit-transform-origin: top;
                  transform-origin: top;
          opacity: 1;
        }
        100% {
          -webkit-transform: rotateX(-100deg);
                  transform: rotateX(-100deg);
          -webkit-transform-origin: top;
                  transform-origin: top;
          opacity: 0;
        }
      }
      @keyframes swing-out-top-bck {
        0% {
          -webkit-transform: rotateX(0deg);
                  transform: rotateX(0deg);
          -webkit-transform-origin: top;
                  transform-origin: top;
          opacity: 1;
        }
        100% {
          -webkit-transform: rotateX(-100deg);
                  transform: rotateX(-100deg);
          -webkit-transform-origin: top;
                  transform-origin: top;
          opacity: 0;
        }
      }
      

 @-webkit-keyframes swing-in-top-fwd {
        0% {
          -webkit-transform: rotateX(-100deg);
                  transform: rotateX(-100deg);
          -webkit-transform-origin: top;
                  transform-origin: top;
          opacity: 0;
        }
        100% {
          -webkit-transform: rotateX(0deg);
                  transform: rotateX(0deg);
          -webkit-transform-origin: top;
                  transform-origin: top;
          opacity: 1;
        }
      }
      @keyframes swing-in-top-fwd {
        0% {
          -webkit-transform: rotateX(-100deg);
                  transform: rotateX(-100deg);
          -webkit-transform-origin: top;
                  transform-origin: top;
          opacity: 0;
        }
        100% {
          -webkit-transform: rotateX(0deg);
                  transform: rotateX(0deg);
          -webkit-transform-origin: top;
                  transform-origin: top;
          opacity: 1;
        }
      }
      
@-webkit-keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  @keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  