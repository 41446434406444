.launch-modal {
    /* transform: translateY(30px); */
    transition: 1s;
    animation: anima 0.5s ease;
}

@keyframes anima {
    0% {
        transform: translateY(-900px);
    }
    100% {
        transform: translateY(0px);
    }
}

.modal-close {
    animation: anima 0.5s ease reverse;
}
